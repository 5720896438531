import React from 'react';
import PageHeader from 'js/components/PageHeader';
import style from './style.css';
import district from './district.js';
import individual from './individual.js';
import school from './school.js';
import preschool from './preschool';
import state from './state.js';
import entity from './entity.js';

const Fortress = window.cccisd.fortress;

export default class Overview extends React.Component {
    getRoleData = () => {
        if (Fortress.hasRole('shapeUser')) {
            return individual;
        }
        if (Fortress.hasRole('schoolAdmin') || Fortress.hasRole('schoolTeamMember')) {
            return school;
        }
        if (Fortress.hasRole('preschoolAdmin') || Fortress.hasRole('preschoolTeamMember')) {
            return preschool;
        }
        if (Fortress.hasRole('districtAdmin') || Fortress.hasRole('districtTeamMember')) {
            return district;
        }
        if (Fortress.hasRole('stateAdmin') || Fortress.hasRole('stateTeamMember')) {
            return state;
        }
        if (Fortress.hasRole('entityAdmin') || Fortress.hasRole('entityTeamMember')) {
            return entity;
        }
    };

    render() {
        const roleData = this.getRoleData();
        let groupHandle = null;

        if (Fortress.user.acting.group) {
            groupHandle = Fortress.user.acting.group.group_type.handle;
        }

        return (
            <div className={style.content}>
                {groupHandle && Fortress.user.acting.group.group_type.handle === 'preschool' ? (
                    <h3 style={{ margin: '2rem 0 5rem 0' }}>{Fortress.user.acting.group.label}</h3>
                ) : (
                    <PageHeader />
                )}
                <div className={style.greenBox}>
                    <div
                        className={style.welcomeIcon}
                        style={{ backgroundImage: `url(${roleData.icon})` }}
                    />
                    {roleData.heading}
                </div>
                <div className={style.container}>
                    {roleData.data.map((cat, i) => {
                        return (
                            <div key={cat.title} className={style.category}>
                                <a href={cat.link} style={{ textDecoration: 'none' }}>
                                    <div className={style.subheading}>
                                        <div className={style.triangle}>
                                            <span className={style.icon}>{cat.icon}</span>
                                        </div>
                                        <div className={style.shText}>{cat.title}</div>
                                    </div>
                                    <div className={style.text}>{cat.text}</div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
