import React from 'react';
import CityIcon from 'cccisd-icons/city';
import style from './style.css';
import IconState from './images/IconState.png';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const Boilerplate = window.cccisd.boilerplate;

export default {
    icon: IconState,
    heading:
        'Welcome to SHAPE! Explore all that SHAPE has to offer to improve your state’s school mental health system.',
    data: [
        {
            title: 'State/Territory Profile',
            icon: <div className={style.MapSchoolMentalHealthServices} />,
            link: Boilerplate.url('/mhProfile'),
            text: (
                <>
                    <li>
                        The State Profile captures information about key policies, partners, and
                        implementation of school mental health.
                    </li>
                    <li>
                        Teams receive a custom report based on responses and strategic planning
                        guides after completing the Profile.
                    </li>
                </>
            ),
        },
        {
            title: 'Resource Library',
            icon: <div className={style.ReceiveCustomReports} />,
            text: (
                <>
                    <li>
                        The Resource Library includes publicly available resources selected by
                        school mental health experts.
                    </li>
                    <li>
                        The library is divided into key components of a comprehensive school mental
                        health system.
                    </li>
                </>
            ),
        },
        {
            title: 'Learn and Share SHAPE',
            icon: <div className={style.UtilizeAdditionalSHAPEFeatures} />,
            link: Boilerplate.url('/learnAndShareShape'),
            text: (
                <>
                    <li>
                        Support your schools and districts as they Map, Assess, and Improve using{' '}
                        <NavLink to="/learnAndShareShape?tab=LearnShareSHAPE&tag=Assessments_State">
                            National Performance Measures.
                        </NavLink>
                    </li>
                    <li>
                        Learn about SHAPE features and how to share information about the SHAPE
                        system with others.
                    </li>
                </>
            ),
        },
        {
            title: 'My Districts',
            icon: <CityIcon />,
            link: Boilerplate.url('/myDistricts'),
            text: (
                <>
                    <li>
                        View data related to school mental health from districts across your state
                        in the “My Districts” tab.
                    </li>
                </>
            ),
        },
        {
            title: 'My Schools',
            icon: <div className={style.UseDistrictStateDashboards} />,
            link: Boilerplate.url('/mySchools'),
            text: (
                <>
                    <li>
                        View data related to school mental health from schools across your state in
                        the “My Schools” tab.
                    </li>
                </>
            ),
        },
    ],
};
