import React from 'react';
import style from './style.css';
import IconSchool from './images/IconSchool.png';
import { NavLink } from 'react-router-dom';

const Boilerplate = window.cccisd.boilerplate;

export default {
    icon: IconSchool,
    heading:
        'Welcome to SHAPE! Explore all that SHAPE has to offer to improve your school mental health system.',
    data: [
        {
            title: 'Assess system quality using national performance measures',
            icon: <div className={style.AssessSystemQuality} />,
            link: Boilerplate.url('/qualityAssessment'),
            text: (
                <>
                    <p>
                        The{' '}
                        <NavLink to="/qualityAssessment" className={style.highlight}>
                            School Mental Health Quality Assessment - Preschool Version
                        </NavLink>{' '}
                        will help your team assess the quality of your preschool mental health
                        system based on six domains. There are a variety of names used to encompass
                        early childhood care and education (i.e., daycare, pre-K, preschool).{' '}
                        <strong>
                            This measure is best suited for early care and education settings with
                            academic programming, serving children from ages 3-5, and preparing them
                            (cognitively and socioemotionally) for kindergarten entry
                        </strong>
                        ; we will refer to this definition as {'"'}preschool{'"'} throughout the
                        survey. Your team will receive custom reports as you complete each domain
                        and a summary report when all domains are completed. All reports are
                        designed to support your preschool team in deciding on next steps for
                        improving your mental health services and supports at your preschool.
                    </p>
                </>
            ),
        },
        {
            title: 'Improve your school mental health system',
            icon: <div className={style.ReceiveCustomReports} />,
            text: (
                <>
                    <li>
                        Custom reports help guide your team’s strategic planning for system
                        improvement.
                    </li>
                    <li>Use the Resource Library to inform continuous quality improvement.</li>
                </>
            ),
        },
        {
            title: 'Learn & Share SHAPE',
            icon: <div className={style.UtilizeAdditionalSHAPEFeatures} />,
            link: Boilerplate.url('/learnAndShareShape'),
            text: (
                <>
                    <li>
                        {' '}
                        Learn about SHAPE features and how to share information about the SHAPE
                        system with others.
                    </li>
                </>
            ),
        },
    ],
};
